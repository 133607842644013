<template>
  <div>
    <el-form
      :label-position="'left'"
      label-width="80px"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item label="id">
        <el-input v-model="term.termId"></el-input>
      </el-form-item>
      <el-form-item label="name">
        <el-input v-model="term.termName"></el-input>
      </el-form-item>
      <el-form-item label="weight">
        <el-input v-model="term.weight"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      :label-position="'left'"
      label-width="80px"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item class="bottomBtn">
        <el-button @click="onSave">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { alertMessage } from "@/util/alertMessageUtil";
import { termApi } from "@/api/termApi";
export default {
  data() {
    return {
      term: {
        termId: 0,
        termName: "",
        weight: 0
      }
    };
  },
  methods: {
    onSave() {
      if (this.term.id <= 0) {
        alertMessage("有ID为0", "warning");
        return;
      }
      termApi
        .addTerm(this.term)
        .then(ret => {
          console.log(ret);
          if (ret.code == 0) {
            alertMessage("保存成功", "success");
            this.term = ret.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    const termId = this.$route.query.termId;
    if (termId) {
      termApi.getTerm(termId).then(ret => {
        console.log("---ret.data", ret.data);
        if (ret.code === 0) {
          this.term = ret.data;
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    }
  }
};
</script>

<style></style>
